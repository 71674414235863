import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"

import TabBar from "../components/policyTabs"
import Container from "../components/container"

export default function BookingTermsAndConditions({ location }) {
  return (
    <Page>
      <SEO title="Travel Credits" />
      <Container>
        <h1 className="tandc">Terms and Conditions</h1>
      </Container>
      <TabBar location={location} />
      <Container>
        <div className="text-container">
          <h1>Travel Credits Terms and Conditions</h1>
          <p>
            These Terms and Conditions outline the details of TravelbyBit Travel
            Credits that are offered to TravelbyBit customers. To be eligible to
            receive Travel Credits, the customer must be over 18 years of age
            and must have a valid account with TravelbyBit. Travel Credits can
            be used toward purchasing travel products on the TravelbyBit website
            (https://travelbybit.com) (the "Site") and are obtained through
            promotions and refunds.
          </p>
          <p>
            The customer must comply with these Terms and Conditions for the
            Travel Credits be valid. By confirming the booking on the Site using
            Travel Credits, the customer agrees to be bound by these Terms and
            Conditions. TravelbyBit's Booking Terms and Conditions apply for any
            bookings made using Travel Credits. The Terms of Use also apply when
            using the Site to redeem Travel Credits.
          </p>

          <p>
            If a customer cancels a booking after it has been finalised using
            Travel Credits, the Travel Credits are deemed to have been redeemed
            and cannot be redeemed again. If the transaction is approved for a
            refund, the customer will receive the approved refund in the form of
            Travel Credits. In the event that TravelbyBit initiates a booking
            cancellation for any reason, TravelbyBit will decide, at its
            discretion, whether the Travel Credits will be refunded to the
            customer.
          </p>
          <p>
            If the law in the state or country of a customer's residence
            prohibits the provision of Travel Credits to the customer, it will
            be decided, at TravelbyBit's sole discretion, whether the Travel
            Credits will be void or adapted to comply with the relevant laws or
            regulations.
          </p>
          <p>
            TravelbyBit reserves the right to modify the Travel Credit Terms and
            Conditions at any time at its discretion with or without notice. If
            a customer is found to be acting in breach of these Terms and
            Conditions, or in an illegal, deceptive or fraudulent manner,
            TravelbyBit reserves the right to render the customer's Travel
            Credits void and disqualify the customer from receiving Travel
            Credits in the future.
          </p>
          <p>
            The customer agrees to release TravelbyBit and any of its affiliates
            from all liability associated with the earning, redemption and use
            of Travel Credits, including any Travel Credits that are lost,
            stolen, destroyed, or otherwise compromised.
          </p>
          <p>
            By agreeing to these Terms and Conditions, the customer consents and
            authorises TravelbyBit to collect, use and disclose any relevant
            information collected by TravelbyBit to: provide services to the
            customer, including the facilitation of third party travel services
            and administration of Travel Credits, and; improve the customer
            service provided by TravelbyBit or any of its affiliates through
            marketing, research and product development. This information will
            be handled in accordance with TravelbyBit’s Privacy Policy.
          </p>
          <p>
            These Terms and Conditions are subject to Australian law, and
            nothing contained within these Terms and Conditions is intended to
            restrict or exclude the rights of a customer under the Australian
            Consumer Law. TravelbyBit makes no guarantees, warranties, or
            representations of any kind in relation to the provision of Travel
            Credits, except where a particular guarantee or warranty cannot be
            excluded under applicable law. Any dispute or claim arising out of
            or in connection with these terms shall be subject to the
            non-exclusive jurisdiction of the courts of Australia.
          </p>
        </div>
      </Container>
    </Page>
  )
}

const Page = styled.div`
  min-height: 100vh;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }

  p {
    margin-bottom: 1em;
  }
`
